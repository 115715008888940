<!-- OK IE11 -->
<template>
  <form @submit.prevent="" class="mb-4">
    <h1 class="h2 mb-3">{{ consentData.consentTitle }}</h1>
    <img
      src="@/assets/underline.png"
      alt="underline"
      class="underlineImg mb-5"
    />

    <div class="row align-items-center mb-4">
      <div class="col">
        <p class="consentText" v-html="consentData.consentText"></p>
      </div>
    </div>

    <div class="row align-items-center mb-4" v-if="consentAlreadyHasValue">
      <div class="col">
        Votre réponse a déjà été enregistrée, vous avez la possibilité de la
        modifier
      </div>
    </div>

    <div class="alert alert-danger" v-if="!!message && message.length > 0">
      {{ message }}
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-1">
        <font-awesome-icon :icon="['fas', 'thumbs-up']" />
      </div>
      <div class="col-2">
        <div
          class="btn btn-secondary radius"
          :class="{ active: isConsentValid == true }"
          @click="isConsentValid = true"
        >
          OUI
        </div>
      </div>

      <div class="col-2 offset-2">
        <div
          class="btn btn-secondary radius"
          :class="{ active: isConsentValid == false }"
          @click="isConsentValid = false"
        >
          NON
        </div>
      </div>
      <div class="col-1">
        <font-awesome-icon :icon="['fas', 'thumbs-down']" />
      </div>
    </div>

    <div class="row align-items-center justify-content-center mt-4">
      <div class="col-4">
        <div class="captchaText">Veuillez renseigner le Captcha ci-dessous</div>
        <div class="d-flex justify-content-center">
          <CaptchaComponent
            captchaWidth="100%"
            imageWidth="220px"
            @captchaChanged="captchaChanged"
            @captchaValueChanged="captchaValueChanged"
          />
        </div>
        <action-button
          text="Valider"
          :action="saveConsent"
          buttonClass="w-100 btn btn-lg btn-primary mt-4 radius"
          :disable="disableConsentButton"
        />
      </div>
    </div>
  </form>
</template>

<script>
import objectHelper from "@/helpers/objectHelper";
import consentServices from "@/services/consentServices";
import userServices from "@/services/userServices";
import userConsentServices from "@/services/userConsentServices";
import CaptchaComponent from "@/components/CaptchaComponent.vue";
import { ConsentCustomContent, UserConsent } from "@/dto";

export default {
  name: "Login",
  emits: ["regenerate", "dataLoaded"],
  components: { CaptchaComponent },
  data: function () {
    return {
      consentData: new ConsentCustomContent(),
      userConsent: new UserConsent(),
      currentUser: null,
      isConsentValid: null,
      captcha: null,
      message: "",
      token: null,
      consentId: null,
    };
  },
  created: async function () {
    this.token = this.$route.query.token;
    this.consentId = this.$route.query.id;
    console.log(this.$route.query.id);
    if (!objectHelper.isDefined(this.consentId) || this.consentId == 0) {
      this.consentId = 1;
    }

    if (objectHelper.isDefined(this.token)) {
      this.currentUser = await userServices.getUser(this.token, this.consentId);
    } else {
      this.$router.push({ name: "404" });
    }
    if (objectHelper.isDefined(this.currentUser)) {
      if (this.currentUser.isTokenUpToDate === false) {
        this.$router.push({ name: "TokenExpired" });
      }

      this.userConsent = await userConsentServices.getUserConsent(
        this.token,
        this.consentId
      );
      if (this.consentAlreadyHasValue) {
        this.isConsentValid = this.userConsent.value;
      }

      this.consentData = await consentServices.getConsentData(
        this.userConsent.consentId
      );
      this.$eventBus.emit("dataLoaded", this.consentData);
    } else {
      this.$router.push({ name: "404" });
    }
  },
  methods: {
    async saveConsent() {
      try {
        this.message = "";

        await userConsentServices.saveUserConsent(
          this.token,
          this.consentId,
          this.isConsentValid,
          this.captcha
        );

        this.$router.push({ name: "ConsentValidation" });
      } catch (error) {
        this.message = objectHelper.isDefined(error.properties[0])
          ? error.properties[0].Message
          : error.message;
        if (!this.message.includes("captcha")) {
          this.message = "Une erreur est survenue";
        }
        this.$eventBus.emit("regenerate");
      }
    },
    captchaChanged(captcha) {
      /** objcet contenant l'id du captcha généré et la valeur du captcha saisie  */
      this.captcha = captcha;
    },
    captchaValueChanged(captchaValue) {
      /** valeur du captcha saisie par l'utilisateur */
      this.captchaValue = captchaValue;
    },
  },
  computed: {
    disableConsentButton() {
      return (
        !objectHelper.isDefined(this.isConsentValid) ||
        !objectHelper.isDefined(this.captcha) ||
        this.captcha.value == ""
      );
    },
    consentAlreadyHasValue() {
      return (
        objectHelper.isDefined(this.userConsent.value) &&
        objectHelper.isDefined(this.userConsent.validityBeginningDate) &&
        objectHelper.isDefined(this.userConsent.validityEndDate)
      );
    },
  },
};
</script>
<style lang="scss" child-component="Captcha">
.captcha {
  width: 287px !important;
}
</style>
<style lang="scss" scoped>
.captchaText {
  color: $blue-app;
}
.consentText {
  color: $blue-app;
  text-align: left;
}
.underlineImg {
  width: 635px;
}
a {
  &:hover {
    text-decoration: underline;
  }
  text-decoration: none;
}
svg {
  font-size: 60px;
  color: $blue-app;
}
.btn-secondary {
  width: 100%;
  &.active {
    background-color: $blue-app !important;
    color: white !important;
  }
}
</style>
