import { ConsentCustomContent } from "@/dto";
import Axios from "axios";

export default {
  /**
   * récupère les data du consentement par id
   * @returns renvoie l'objet ou null.
   */
  async getConsentData(id) {
    var response = await Axios.get("api/consent/" + id);
    if (response) {
      return new ConsentCustomContent(response);
    } else {
      return null;
    }
  },
};
