import { UserConsent } from "@/dto";
import Axios from "axios";

export default {
  /**
   * récupère le consentement par token
   * @returns renvoie l'objet ou null.
   */
  async getUserConsent(token, consentId) {
    var response = await Axios.get(
      "api/user-consent/" + token + "/" + consentId
    );
    if (response) {
      return new UserConsent(response);
    } else {
      return null;
    }
  },
  /**
   * Sauvegarde le consentement du user
   * @returns renvoie l'objet ou null.
   */
  async saveUserConsent(token, consentId, value, captcha) {
    await Axios.post("api/user-consent/" + token + "/" + consentId, {
      value: value,
      captchaUserResponse: captcha,
    });
  },
};
